export const SHOW_TOSTER = "SHOW_TOSTER";
export const OTP_VERIFIED = "OTP_VERIFIED";
export const RESEND_OTP_VERIFIED = "RESEND_OTP_VERIFIED";
export const GET_LOGIN = "GET_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const GOOGLE_LOGIN = "GOOGLE_LOGIN";
export const GET_PUBLIC_POST = "GET_PUBLIC_POST";
export const GET_PUBLIC_POST_DETAILS = "GET_PUBLIC_POST_DETAILS";
export const GET_NAVBAR_MENULIST = "GET_NAVBAR_MENULIST";
export const GET_PUBLIC_LACAL_NEWS = "GET_PUBLIC_LACAL_NEWS";
export const POST_COMMENT = "POST_COMMENT";
export const LATEST_POST = "LATEST_POST";
export const MORE_POST = "MORE_POST";
export const GET_TRANDING = "GET_TRANDING";
export const CATEGORY_DETAIL = "CATEGORY_DETAIL";
export const GLOBAL_SEARCH = "GLOBAL_SEARCH";
export const FOR_YOU_DATA = "FOR_YOU_DATA";
export const YOUR_TOPIC_DATA = "YOUR_TOPIC_DATA";
export const PICK_FOR_YOU_DATA = "PICK_FOR_YOU_DATA";
export const STORE_COMMENT = "STORE_COMMENT";
export const GET_BACK_TO_DETAILS = "GET_BACK_TO_DETAILS";
export const GET_SITEMAP = "GET_SITEMAP";

// private api action type
export const GET_TOP_STORIES = "GET_TOP_STORIES";
export const GET_FOLLOW_INTEREST = "GET_FOLLOW_INTEREST";
export const POST_INTEREST = "POST_INTEREST";
export const GET_FOLLOWING_DATA = "GET_FOLLOWING_DATA";
export const POST_FOLLOWING_DATA = "POST_FOLLOWING_DATA";
export const SELECTED_INTERESTED_DATA = "SELECTED_INTERESTED_DATA";
export const GET_TAGS_DATA = "GET_TAGS_DATA";
export const POST_TOPICS_TAGS_DATA = "POST_TOPICS_TAGS_DATA";
export const GET_SEARCH_TAGS_DATA = "GET_SEARCH_TAGS_DATA";
export const GET_MODEL_INTEREST_DATA = "GET_MODEL_INTEREST_DATA";
export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const GET_ACCOUNT = "GET_ACCOUNT";
export const SELECTED_TAGS_ISBOREDED = "SELECTED_TAGS_ISBOREDED";
export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const SELECTED_PLAN = "SELECTED_PLAN";
export const POST_SUBSCRIPTION = "POST_SUBSCRIPTION";
export const GET_VERIFIED = "GET_VERIFIED";
export const CREATE_PAYMENT = "CREATE_PAYMENT";

// POLLING ACTION
export const POLLING_TABLE_LIST = "POLLING_TABLE_LIST";
export const DELHI_TABLE_LIST = "DELHI_TABLE_LIST";
export const POLLS_AVERAGE_DATA = "POLLS_AVERAGE_DATA";
export const ELECTORAL_VOTES = "ELECTORAL_VOTES";

// FAQ TYPE
export const GET_FAQ_DATA = "GET_FAQ_DATA";