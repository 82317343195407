import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getDelhiPolls } from "../../redux/actions/pollingAction";
import DynamicTable from "../DynamicTable/DynamicTable";
import moment from "moment";
import { BASE_URL_PP } from "../../redux/api";

const PollBadge = ({ AAP, BJP, CONG, Others }) => {
  return (
    <div className="overflow-x-auto w-[20rem]">
      <table className="min-w-full  ">
        <tbody className="divide-y divide-transparent">
          <tr className="flex items-baseline">
            {/* Democratic Candidate */}
            {/* <td className="flex-1 text-right pt-1">{"5555"}</td> */}
            {/* Results Container */}
            <td className="flex-1 py-1 px-2 ">
              <div className="grid grid-cols-4 gap-2 divide-x divide-transparent">
                {/* Democratic Result Button */}
                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs p-1 px-0 bg-black text-nowrap text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-r-none"
                  type="button"
                >
                  {AAP || 0}
                </button>
                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-0 text-nowrap bg-yellow-500 text-black shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-l-none"
                  type="button"
                >
                  {BJP || 0}
                </button>

                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-1 px-0 text-nowrap bg-[#4252A8] text-white shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-l-none"
                  type="button"
                >
                  {CONG || 0}
                </button>
                <button
                  className="flex-1 align-middle select-none font-sans text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs p-1 px-0 bg-gray-400 text-nowrap text-black shadow-md focus:opacity-[0.85] active:opacity-[0.85] rounded-r-none"
                  type="button"
                >
                  {Others || 0}
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

function PollingTable() {
  const dispatch = useDispatch();
  const { delhiElectionData } = useSelector((state) => state.pollWeb);
  const [candidateList, setCandidateList] = useState([]);
  const [candidate, setCandidate] = useState("");
  const [update, setUpdate] = useState(false);

  const currentDateTime = new Date();
  const dataList = {
    column: [
      {
        title: " DATES",
        field: "createdAt",
        customField: (row) =>
          row?.createdAt ? moment(row?.createdAt).format("DD/MM/YYYY") : "",
      },
      {
        title: "SAMPLE",
        field: "",
        customField: (row) => row.Poll_Type_LV ?? row.Poll_Type_RV,
      },
      {
        title: "POLLSTER",
        field: "pollsterName",
        customField: (row) => row.pollster ?? "-",
      },

      {
        title: "ACCURACIES",
        field: "accuracies",
        customField: (row, idx) => (
          <>
            {idx === 0 && (
              <>
                <div className="text-[12px] grid grid-cols-4 gap-1">
                  <p>AAP</p>
                  <p>BJP</p>
                  <p>CON</p>
                  <p>OTH</p>
                </div>
              </>
            )}

            <PollBadge
              AAP={row?.AAPAccuracy}
              BJP={row?.BJPAccuracy}
              CONG={row?.INCAccuracy}
              Others={row?.OtherAccuracy}
            />
          </>
        ),
      },

      {
        title: "SPONSOR",
        field: "publisher",
        customField: (row) => row.publisher ?? "-",
        // customField: (row) => (row?.createdAt ? moment(row?.createdAt).format('MM/DD/YYYY, hh:mm A') : '')
      },
      {
        title: " POLL %",
        field: "",
        borderleft: true,
        customField: (row, idx) => (
          <>
            {idx === 0 && (
              <>
                <div className="text-[12px] grid grid-cols-4 gap-1">
                  <p>AAP</p>
                  <p>BJP</p>
                  <p>CON</p>
                  <p>OTH</p>
                </div>
              </>
            )}
            <PollBadge
              AAP={row?.aap_seats}
              BJP={row?.bjp_seats}
              CONG={row?.inc_seats}
              Others={row?.others_seats}
            />
          </>
        ),
      },
      {
        title: " RESULT %",
        field: "",
        borderleft: true,
        customField: (row, idx) => (
          <>
            {idx === 0 && (
              <>
                <div className="text-[12px] grid grid-cols-4 gap-1">
                  <p>AAP</p>
                  <p>BJP</p>
                  <p>CON</p>
                  <p>OTH</p>
                </div>
              </>
            )}
            <PollBadge
              AAP={row?.aap_voteshare_result}
              BJP={row?.bjp_voteshare_result}
              CONG={row?.inc_voteshare_result}
              Others={row?.others_voteshare_result}
            />
          </>
        ),
      },
    ],
  };
  const quary = `rows=${200}`;
  useEffect(() => {
    dispatch(getDelhiPolls());
    if (quary) {
    }
  }, []);

  console.log("delhiElectionData ", delhiElectionData);

  const handelCandidate = (e) => {
    const candidate = e.target.value;
    setCandidate(candidate);
  };
  return (
    <div className="container mx-auto py-4 border rounded-md border-gray-200 capitalize">
      {/* Search and Dropdown */}
      <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-4 px-4">
        <div className="flex flex-col mb-4 md:mb-0">
          <div className="text-lg font-semibold pb-1"> polls</div>
          <div className="text-sm text-gray-500">
            Latest Poll:{" "}
            {`${
              delhiElectionData?.[0]?.updatedAt
                ? moment(delhiElectionData?.[0]?.updatedAt).format("llll")
                : ""
            }`}
          </div>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <DynamicTable column={dataList?.column} data={delhiElectionData} />
      </div>
    </div>
  );
}

export default PollingTable;
