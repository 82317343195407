import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import delhimap from "../../assets/icons/delhiMap.svg";
import redUpMap from "../../assets/icons/upRedMap.svg";
import grayDelhimap from "../../assets/icons/delhiGraymap.svg";
import grayUpMap from "../../assets/icons/upMap.svg";
import IndianFlag from "../../assets/images/flag-img/Mask Group 380ind.png";
import { useEffect, useState } from "react";
function IndianPolls() {
  const location = useLocation();
  let { slug } = useParams();
  const isVerified = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
  }, []);
  const [hoveredId, setHoveredId] = useState(null);
  const [imageChange, setimageChange] = useState({
    id: null,
    isHover: "",
  });

  const mapArray = [
    {
      id: 1,
      name: "Delhi",
      map: grayDelhimap,
      hoverMap: delhimap,
    },
    {
      id: 2,
      name: "Uttar Pradesh",
      map: grayUpMap,
      hoverMap: redUpMap,
    },
  ];

  return (
    <>
      <div className="bg_home flex flex-col pb-8">
        <div className="flex flex-row px-6 md:px-40 gap-4 text-[12px] font-semibold bg-[#E2E7EE]">
          <Link
            to={`/india-polls/general-stories`}
            className={`py-2 nav-link-hover-poll ${
              location.pathname === `/india-polls/general-stories`
                ? "font-bold "
                : "text-[#7F8FA4]"
            }`}
          >
            General Stories
          </Link>
          <Link
            to={`/india-polls/polling`}
            className={`py-2 ${
              location.pathname === `/india-polls/polling`
                ? "font-bold "
                : "text-[#7F8FA4]"
            }`}
          >
            Polls
          </Link>
          <Link
            //   to={`/${slug}/polling`}
            className={`py-2 nav-link-hover-poll ${
              location.pathname === `/india-polls/prediction`
                ? "font-bold "
                : "text-[#7F8FA4]"
            }`}
          >
            Predictions
          </Link>
        </div>
        <div className="bg_home md:bg-[#F7F8FA] px-4  md:px-40 flex flex-col pb-6 md:py-5">
          <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5   md:block">
            <div className=" h-auto pb-14 ">
              <div className="text-[20px] text-[#7F8FA4]">Lok Sabha Polls</div>
              <div className="my-2">
                <div className="m-auto  min-[380px]:m-0 size-[160px] bg-[#F7F8FA] cursor-pointer rounded-xl flex justify-center items-center flex-col">
                  <img src={IndianFlag} alt="indian-flag" />
                  <p className="mt-5 text-[12px] font-semibold">India Polls</p>
                </div>
              </div>
            </div>
            <div className=" h-auto pb-14 ">
              <div className="text-[20px] text-[#7F8FA4]">
                Vidhan Sabha Polls
              </div>
              <div className="my-2 grid grid-cols-1 min-[380px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-2">
                {mapArray?.map((res) => {
                  const isHovered = hoveredId === res.id;
                  return (
                    <div
                      key={res?.id}
                      className="m-auto  min-[380px]:m-0 size-[160px] hover:bg-white bg-[#F7F8FA] hover:border-2 border-red-500 cursor-pointer rounded-xl flex justify-center items-center flex-col"
                      onClick={() => navigate("/india-polls/delhi-polls")}
                      onMouseEnter={() => setHoveredId(res?.id)}
                      onMouseLeave={() => setHoveredId(null)}
                    >
                      <img
                        src={isHovered ? res?.hoverMap : res?.map}
                        alt="indian-flag"
                      />
                      <p className="mt-5 text-[12px] font-semibold">
                        {res?.name} Polls
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default IndianPolls;
