import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import delhimap from "../../assets/icons/delhiMap.svg";
import PollingTableNew from "../../components/PollingTable/PollingTableNew";
import appicon from "../../assets/icons/Aam_Aadmi_Party_flag_(2).svg@2x.png";
import congFlag from "../../assets/icons/congFlag.svg";
import bjpFlag from "../../assets/icons/BJP_Flag.svg";
import othersFlag from "../../assets/icons/0thersflag.svg";
import { useEffect, useState } from "react";
import { Cell, Legend, Pie, PieChart } from "recharts";
import { Tooltip } from "@material-tailwind/react";

const Delhipolls = () => {
  const location = useLocation();
  let { slug } = useParams();
  const isVerified = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  useEffect(() => {
    if (!isVerified) {
      navigate("/login");
    }
  }, []);

  // Data for Pie Chart
  const data = [
    { name: "Others", value: 100 },
    { name: "CONG", value: 150 },
    { name: "BJP", value: 400 },
    { name: "APP", value: 250 },
  ];
  const COLORS = ["#AAAAAA", "#4251A8", "#E69600", "#000"];

  return (
    <div className="bg_home flex flex-col pb-8">
      <div className="flex flex-row px-6 md:px-40 gap-4 text-[12px] font-semibold py-2 bg-[#E2E7EE] ">
        <Link
          to={`/india-polls/general-stories`}
          className={` ${
            location.pathname === `/india-polls/general-stories`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          General Stories
        </Link>
        <Link
          to={`/india-polls/polling`}
          className={` ${
            location.pathname === `/india-polls/delhi-polls`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          Polls
        </Link>
        <Link
          //   to={`/${slug}/polling`}
          className={` ${
            location.pathname === `/india-polls/prediction`
              ? "font-bold "
              : "text-[#7F8FA4]"
          }`}
        >
          Predictions
        </Link>
      </div>
      <div className="bg_home md:bg-[#F7F8FA] px-4 md:px-40 flex flex-col pb-6 md:py-5">
        <div className="bg-white w-full rounded-xl shadow-sm p-3 md:p-5 overflow-hidden">
          <div className="h-auto pb-5">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-start items-center gap-2">
                <div className="size-[20px]">
                  <img
                    src={delhimap}
                    alt="delhi-map"
                    className="object-contain"
                  />
                </div>
                <div className="text-[20px] font-semibold">Delhi Polls</div>
              </div>
            </div>
          </div>
          <div className="h-auto mt-10">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between items-center mb-14">
                <div className="grid md:grid-cols-1 xl:grid-cols-2 w-full lg:w-[80%] items-center">
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 ms-5">
                      <div className="min-w-14 max-w-14">
                        <img src={appicon} alt="appicon" />
                      </div>
                      <div className="text-[14px] font-semibold">
                        <p className="mb-1">Aam Aadmi Party</p>
                        <div className="w-[40vw] md:w-[18vw] bg-gradient-to-r via-gray-400 from-black  to-white flex justify-end">
                          29.50%
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 ms-5">
                      <div className="min-w-14 max-w-14">
                        <img src={bjpFlag} alt="appicon" />
                      </div>
                      <div className="text-[14px] font-semibold">
                        <p className="mb-1">Bhartiya Janta Party</p>
                        <div className="w-[46vw] md:w-[20vw] bg-gradient-to-r via-orange-300 from-orange-500 to-orange-100 flex justify-end">
                          33%
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 ms-5">
                      <div className="min-w-14 max-w-14">
                        <img src={congFlag} alt="appicon" />
                      </div>
                      <div className="text-[14px] font-semibold">
                        <p className="mb-1">Congress</p>
                        <div className="w-[36vw] md:w-[15vw] bg-gradient-to-r via-indigo-400 from-indigo-800 to-indigo-200 flex justify-end">
                          24.60%
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2 ms-5">
                      <div className="min-w-14 max-w-14">
                        <img src={othersFlag} alt="appicon" />
                      </div>
                      <div className="text-[14px] font-semibold">
                        <p className="mb-1">Others</p>
                        <div className="w-[18vw] md:w-[7vw] bg-gradient-to-r from-gray-500 to-white flex justify-end">
                          5.35%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-[85vw] md:w-[38vw] flex justify-end">
                    <PieChart width={642} height={330}>
                      <Pie
                        data={data}
                        cx={300}
                        cy={140}
                        innerRadius={70}
                        outerRadius={125}
                        fill="#8884d8"
                        paddingAngle={5}
                        // labelLine={false}
                        dataKey="value"
                        label={({
                          cx,
                          cy,
                          midAngle,
                          innerRadius,
                          outerRadius,
                          index,
                        }) => {
                          const RADIAN = Math.PI / 180;
                          const radius =
                            innerRadius + (outerRadius - innerRadius) * 1.5;
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);

                          const sliceData = data[index];
                          return (
                            <g>
                              <text
                                x={x}
                                y={y - 10}
                                fill={COLORS[index % COLORS.length]}
                                textAnchor={x > cx ? "start" : "end"}
                                dominantBaseline="central"
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                {`Male ${sliceData.value}`}
                              </text>
                              <text
                                x={x}
                                y={y + 10}
                                fill={COLORS[index % COLORS.length]}
                                textAnchor={x > cx ? "start" : "end"}
                                dominantBaseline="central"
                                style={{ fontSize: "14px", fontWeight: "bold" }}
                              >
                                {`Female ${sliceData.value}`}
                              </text>
                              {/* <text
                                x={x}
                                y={y + 30}
                                fill={COLORS[index % COLORS.length]}
                                textAnchor={x > cx ? "start" : "end"}
                                dominantBaseline="central"
                                style={{ fontSize: "12px", fontWeight: "bold" }}
                              >
                                {`Others ${sliceData.value}`}
                              </text> */}
                            </g>
                          );
                        }}
                      >
                        {data.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>

                      <Legend
                        verticalAlign="top"
                        align="right"
                        height={36}
                        wrapperStyle={{
                          top: 350,
                          right: 170,
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      />
                    </PieChart>
                  </div>
                </div>
              </div>
            </div>

            {/* Polling chart end */}
            <div className="flex flex-col gap-3">
              <div className="text-[21px] font-semibold">Polling Table</div>
              <PollingTableNew />
            </div>
            <div className="py-6">
              <div className="text-[12px] text-gray-700 font-semibold">
                Summary
              </div>
              <p
                className="text-[12px] text-gray-500 py-2 leading-5 text-justify"
                onMouseEnter={() => {
                  console.log();
                }}
              >
                {/* Summary content */}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                vel auctor magna, ut tristique sapien. Nunc ut ligula placerat,
                tempus risus sed, vehicula massa. Sed rutrum ultrices libero,
                sit amet suscipit magna vulputate vel. Curabitur hendrerit
                ornare risus. Praesent et eleifend neque. Nam eget massa massa.
                Fusce orci augue, egestas vitae ultrices eu, facilisis at lacus.
                Donec condimentum eros at justo hendrerit, vitae congue purus
                eleifend. Nam facilisis nulla sem, ac bibendum felis accumsan
                at. Proin hendrerit ac ipsum non lobortis. Proin eget porta
                turpis, in rhoncus massa. Donec sit amet nibh et mi congue
                pretium quis eu nibh. Pellentesque tempus sem ac orci rhoncus
                hendrerit. Aenean vitae orci ut diam sollicitudin tincidunt sit
                amet ac lectus. Proin felis sem, pellentesque sit amet diam
                vitae, suscipit fermentum est. Vestibulum quis vulputate felis.
                Nullam ut orci non metus eleifend condimentum eget et mi. Morbi
                rhoncus commodo est sed sodales. Vestibulum vehicula ligula
                risus, ut vulputate nulla pretium vel. Quisque semper nulla
                lorem, sed ultricies lectus venenatis nec. Proin non mi pretium,
                cursus ligula ac, suscipit nisi. Pellentesque mollis sodales
                ligula a feugiat. Sed in neque quis dolor ullamcorper auctor
                quis ac ipsum. Quisque at nisi sed sapien aliquam laoreet eget
                eget dolor. Donec euismod enim lectus, eu elementum mi facilisis
                ut. Curabitur ultrices nulla a magna laoreet sollicitudin.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delhipolls;
